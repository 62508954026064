<template>
  <div id="footer" class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-3 info-col">
        <div class="logo">
          <img :src="$previewFile(logoSite)" alt="logo图">
        </div>
        <p class="title">{{sloganSite}}</p>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 info-col">
        <p class="address_tel_fax">
          <span>地址：{{addressSite}} </span>
          <span class="pointer glyphicon glyphicon-map-marker" style="font-size: 20px" aria-hidden="true" @click="goMap"></span>

        </p>
        <p class="address_tel_fax">
          <span>邮箱：{{emailSite}} </span>
          <!--<span>公司微信号：{{wechatSite}} </span>-->
        </p>
        <p class="address_tel_fax">
          <span>电话：{{phoneSite}} </span>
        </p>
        <p class="address_tel_fax">
          <span> 手机：{{telephoneSite}} </span>
        </p>
      </div>
      <div class="clearfix  visible-sm-block"></div>
      <div class="col-xs-12 col-sm-6 col-md-2 info-col ">
        <span v-if="QRWechatSite" class="qr-title">关注公众号</span>
        <div>
          <div class="qr-code-box" v-if="QRWechatSite">
            <el-popover
                    placement="bottom"
                    trigger="hover"
                    content="">
              <img class="qr-code-hover" :src="$previewFile(QRWechatSite)"/>
              <img slot="reference" class="qr-code pointer" :src="$previewFile(QRWechatSite)"/>
            </el-popover>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-3 info-col">
        <span class="qr-title" v-if="QRXiaochengxuSite||QRIosSite||QRAndroidSite">app下载</span>
        <div>
          <div class="qr-code-box" v-if="QRXiaochengxuSite">
            <el-popover
                    placement="bottom"
                    trigger="hover"
                    content="">
              <img class="qr-code-hover" :src="$previewFile(QRXiaochengxuSite)"/>
              <img slot="reference" class="qr-code pointer" :src="$previewFile(QRXiaochengxuSite)"/>
            </el-popover>
            <span>微信小程序</span>
          </div>

          <div class="qr-code-box" v-if="QRIosSite">
            <el-popover
                    placement="bottom"
                    trigger="hover"
                    content="">
              <img class="qr-code-hover" :src="$previewFile(QRIosSite)"/>
              <img slot="reference" class="qr-code pointer" :src="$previewFile(QRIosSite)"/>
            </el-popover>
            <!--<img class="qr-code" :src="$previewFile(QRIosSite)"/>-->
            <span>ios</span>
          </div>

          <div class="qr-code-box" v-if="QRAndroidSite">
            <el-popover
                    placement="bottom"
                    trigger="hover"
                    content="">
              <img class="qr-code-hover" :src="$previewFile(QRAndroidSite)"/>
              <img slot="reference" class="qr-code pointer" :src="$previewFile(QRAndroidSite)"/>
            </el-popover>
            <!--<img class="qr-code" :src="$previewFile(QRAndroidSite)"/>-->
            <span>android</span>
          </div>
        </div>
      </div>
    </div>
    <el-divider></el-divider>
    <!--<p class="copy">Copyright &copy; {{copyrightSite}}</p>-->

    <div class="footer-info">
      <div class="pointer" @click="goOut('https://beian.miit.gov.cn/#/Integrated/index')">浙ICP备19050050号-1</div>
      <div class="pointer" @click="goOut('https://beian.miit.gov.cn/ ')">增值电信业务经营许可证：浙B2-20200561</div>
      <div>Copyright &copy; {{copyrightSite}}</div>
    </div>


  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import {getOpenMapUrl} from "@/utils/dataHandle";
export default {
  name: "Footer",
  computed:{
    ...mapGetters([
        'companyNameSite',
      'logoSite',
      'logoWhiteSite',
      'sloganSite',
      'addressSite',
      'phoneSite',
      'telephoneSite',
      'emailSite',
      'wechatSite','copyrightSite','geoSite',
      'QRWechatSite',
      'QRXiaochengxuSite',
      'QRIosSite',
      'QRAndroidSite',
    ]),
  },
  data() {
    return {};
  },
  methods:{
    goMap(){

      let geoArr =this.geoSite.split(",");
      window.open(getOpenMapUrl(geoArr[1],geoArr[0],this.companyNameSite,this.addressSite,'baidu'))
    },
    goOut(url){

      window.open(url)
    }
  }
};
</script>
<style lang="scss" scoped>

.qr-code-hover{
  height: 200px;
  width: 200px;
}

#footer {
  width: 100%;
  /*height: 100%;*/
  color: $-color-font-3;
  background: $-color-background;
  overflow: hidden;
  text-align: center;
  padding-top: 50px;

  .qr-code-hover{
    height: 150px;
    width: 150px;
  }

  .info-col{
    /*height: 150px;*/
    .qr-code-box{
      display: inline-block;
      margin:10px;
      span{
        color: #767676;
      }
    }
    .qr-code-box +.qr-code-box{
      /*margin-left: 20px;*/
    }
    .qr-code{
      /*display: inline-block;*/
      /*margin:5px auto 10px auto;*/

      height: 80px;
    }

    .qr-code +.qr-code{
      margin-left: 20px;
    }
  }
}
.logo {
  max-width: 180px;
  max-height: 45px;
  margin: 30px auto 20px;
  img{
    width: 100%;
    height: 100%;
  }
}
.qr-title{
  margin-top: 10px;
  display: block;
}
.title {
  font-size: 25px;
  margin-bottom: 20px;
}
.address_tel_fax {
/*  color: $-color-font-3;*/
  font-size: 14px;
  margin: 10px 0;
  text-align: left;
}
.email_wx {
 /* color: $-color-font-3;*/
  font-size: 14px;
}
.copy {
/*  color: $-color-font-3;*/
  font-size: 14px;
  margin: 50px 0 10px;
}


.footer-info {
  /*  color: $-color-font-3;*/
  font-size: 14px;
  margin: 0 0 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;


}
@media screen and (max-width: 997px) {

  .title {
    font-size: 20px;
  }
  .address_tel_fax {
    font-size: 12px;
    text-align: center;
  }
  .email_wx {

  font-size: 12px;
}
  .copy {
    font-size: 12px;
    margin: 30px 0 10px;
  }
}
</style>

